<template>
  
    <v-col cols=12 :md="settingsTableWider ? 12 : 9" class="pt-0"> 
      <keep-alive>
        <!--<JobModalAdd ref="addJob" v-on:update-job="updateTable += 1"/>-->
      </keep-alive>   
      <keep-alive>
        <Filters ref="filtersPays" v-on:apply-filters="checkGetPays(), $parent.$refs.navbarTable.filter_date = ''" for="pays" />
      </keep-alive>
      <keep-alive>
        <AbonentInfoModal ref="AbonentInfoModal" backTo="Платежі" />
      </keep-alive>
      
       
        <v-data-table
          @click:row="onClickRow"
          :headers="headersList"
          :dense="settingsTableDanse || $vuetify.breakpoint.smAndDown"
          loading-text="Завантаження..."
          no-data-text="Немає платежів для відображення"
          :items="pays"
          :options.sync="options"
          :page.sync="page"
          :server-items-length="paginations.count"
          :loading="loading"
          class="elevation-1 row-cursor"
          :class="$vuetify.breakpoint.smAndDown ? $vuetify.theme.dark && 'td_background_dark' || 'td_background':''"
          :footer-props="footer"
          focusable 
        >
          <template v-slot:top>
            <v-toolbar flat color="secondary lighten-1" dark>

            <template v-if="!settingsTableBar">
              <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''" @click="$emit('show-field-date')"><h2 class="mr-6 accent--text">Платежі</h2></v-toolbar-title>
              
              <v-spacer/>
              <v-btn :icon="$vuetify.breakpoint.mobile" :outlined="!$vuetify.breakpoint.mobile" small :color="!inetPays ? 'accent' : 'grey'"
              :class="!inetPays ? 'primary--text' : ''"
              @click="inetPays = false, checkGetPays()"
              >
                 <v-icon v-if="$vuetify.breakpoint.mobile">mdi-television</v-icon> 
                 <span v-else>ТБ</span>
              </v-btn>
              <v-btn :icon="$vuetify.breakpoint.mobile" :outlined="!$vuetify.breakpoint.mobile" small :color="inetPays ? 'accent' : 'grey'"
              :class="inetPays ? 'primary--text' : ''"
              class="ml-3"
              @click="inetPays = true, checkGetPays()"
              >
                <v-icon v-if="$vuetify.breakpoint.mobile">mdi-web</v-icon> 
                <span v-else>Інтернет</span>
              </v-btn>
              <v-spacer />
              <v-btn 
                :color="settingsTableWider ? 'accent' : ''"
                title="Більше данних"
                icon
                @click="settingsTableWider = !settingsTableWider">
                  <v-icon>mdi-unfold-more-vertical</v-icon> 
              </v-btn>
              <v-btn 
                title="Налаштування таблиці"
                icon
                @click="settingsTableBar = !settingsTableBar"
              >
                <v-icon>mdi-cog</v-icon>
              </v-btn> 

              <v-btn 
                title="Поновити данні"
                icon 
                :loading="loading"
                @click="getPays()">
                  <v-icon>autorenew</v-icon>
              </v-btn>
                
              <v-btn  
                title="Фільтри пошуку" 
                icon 
                :disabled="loading"
                @click="$refs.filtersPays.show()"
              >
                <v-badge
                :value="countFilters > 0"
                color="accent"
                text="primary"
                :content="countFilters"
                dark
              >
                <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <!------------------------------------------------------------------------------------------>

            <template v-else> 
              <v-toolbar-title v-if="$vuetify.breakpoint.mdAndUp"><h4>Налаштування</h4></v-toolbar-title>
              <v-spacer />
              <v-btn 
                :color="settingsTableDanse ? 'accent' : ''"
                title="Збільшити/зменшити інтервал між рядками"
                class="mr-3"
                outlined
                small
                @click="settingsTableDanse = !settingsTableDanse"
              >
                <v-icon left>mdi-arrow-collapse-vertical</v-icon>
                Рядки
              </v-btn>
              <v-btn 
                :color="settingsTableWider ? 'accent' : ''"
                title="Збільшити/зменшити ширину таблиці"
                class="mr-3"
                outlined
                small
                @click="settingsTableWider = !settingsTableWider"
              >
                <v-icon left>mdi-unfold-more-vertical</v-icon>
                Ширше
              </v-btn>  
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    title="Редагування відображення колонок"
                    class="mr-4"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    small
                    dark
                  >
                  <v-icon left >mdi-view-column</v-icon>
                    Колонки
                  </v-btn>
                </template>
                <v-sheet max-height="500" style="overflow-y: auto;">
                  <v-list dense max-height="500">
                    <v-list-item>
                      <v-btn
                        :disabled="!changeHeaders"
                        color="primary"
                        x-small
                        block
                        @click="saveHeaders()"
                      >
                        Зберегти {{settingsTableWider ? 'для розширеної' : 'за замовчуванням' }} 
                      </v-btn>
                      </v-list-item>
                      <v-list-item>
                      <v-btn
                        color="error"
                        outlined
                        x-small
                        block
                        @click="getDefaultHeaders()"
                      >
                        Скинути
                      </v-btn>
                    </v-list-item>
                    <v-divider />
                    <draggable v-model="headers" :animation="200" handle=".handle">
                      <v-list-item v-for="(item, index) in headers" :value="item.value" :key="index" >
                        <v-list-item-icon class="mr-1"><v-icon small v-if="item.view">mdi-check</v-icon></v-list-item-icon>
                        <v-list-item-title class="handle-click" @click="item.view = !item.view">{{ item.text }}</v-list-item-title>
                        <v-list-item-icon class="ml-6"><v-icon small class="handle">mdi-drag</v-icon></v-list-item-icon>
                      </v-list-item>
                    </draggable>
                  </v-list>
                </v-sheet>
              </v-menu>
              <v-btn icon @click="settingsTableBar = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            

            <!------------------------------------------------------------------------------------------>
                
            </v-toolbar>
          </template>

          <template
            v-if="countFilters > 0"
            v-slot:header="{ props: { headers } }"
          >
            <thead>
              <tr>
                <th :colspan="headers.length">
                  Фільтри: 
                  <keep-alive>
                    <FiltersChips :filtersComponents="filtersComponents" v-on:clear-filter="clearFilter" :is_close="true" v-on:open-filters="$refs.filtersPays.show()"/>
                  </keep-alive>
                </th>
              </tr>
            </thead>
          </template> 
          
          

        <!-- ---------------------------------------- Cols ----------------------------------------- -->

          <template #item.index="{ item }"> 
            <span>{{ pays.indexOf(item) + paginations.start_index }}</span>
          </template>
          <template #item.date="{ item }">
            <v-tooltip bottom color="primary" :disabled="settingsTableWider">
              <template v-slot:activator="{ on }">
                <span v-on="on">{{ item.date }}</span>
              </template>
              <span>Дата проведення платежу:<br>{{ item.date_pay }}</span>
            </v-tooltip>
          </template>
          <template #item.service="{ item }">
            <v-icon v-if="item && item.service == 1" color="green darken-2" >mdi-television</v-icon> 
            <v-icon v-else color="light-blue darken-2" >mdi-web</v-icon> 
          </template>
          <template #item.address="{ item }">
            <div href="#" v-if="true"  class="link_text" :class="$vuetify.theme.dark ? 'primary--text text--lighten-1' : ''" @click.prevent="openAbonentInfoModal(item)">{{ item && item.address }} <v-icon title="Видалений абонент" color="error" style="vertical-align: baseline;" small class="ml-1" v-if="item && item.is_removed">mdi-alert-circle</v-icon></div>
          </template>
          <template #item.operator="{ item }">
            <v-avatar
              v-if="!settingsTableWider"
              :color="colorsDistributor[findDistributor(item.distributor, 'letter')]"
              size="28"
              :title="findDistributor(item.distributor, 'title')"
            >
              <span class="white--text text-subtitle-2">{{ findDistributor(item.distributor, 'short') }}</span>
            </v-avatar>
            <span v-else>{{ findDistributor(item.distributor, 'title') }}</span>
          </template>
          <template #item.amount="{ item }">
            <span :class="{'deep-orange--text': item.amount < 0}">{{ item.amount}}</span>
          </template>
          <template #item.type_pay="{ item }">
            <v-badge
              :value="item && item.manual"
              inline
              icon="mdi-hand-back-left"
              :title="item && item.manual && `Ручне проведення\nОбробив: ${item && item.user_worker}`"
            >{{ item && item.type_pay && pays_type.find(x => x.id == item.type_pay).name}}</v-badge>
             
          </template>
          <template #item.memo="{ item }">
            <span v-if="!settingsTableWider" :title="item.memo">{{ item && item.memo && formatMemo(item.memo) }} </span>
            <span v-else>{{ item.memo }}</span>
          </template>

        <!-- ---------------------------------------- /Cols ----------------------------------------- -->
        <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
          {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
        </template>
        </v-data-table>
      


    </v-col>
</template>

<script>
import axios from "axios"
import { mapGetters } from "vuex"
//import JobModalAdd from '@/components/Job/JobModalAdd.vue'
import Filters from '@/components/Abonents/Filters.vue'
import FiltersChips from '@/components/Abonents/FiltersChips.vue'
import AbonentInfoModal from '@/components/Job/AbonentInfoModal.vue'

import { settingsTable } from '@/mixins/settingsTable.js'
import draggable from "vuedraggable"


export default {
  name: 'pays-table',
  mixins: [settingsTable],
  components: {
    Filters,
    FiltersChips,
    AbonentInfoModal,
    draggable
  },
  props: ["user", "tabData"], 
  data: () => ({
    settingsTableWider: false,
    filter_date: new Date().toDateInputValue(),
    updateTable: 0,
    pays: [],
    paginations: {},
    loading: false,
    options: {},
    page: 1,
    countFilters: 0,
    inetPays: false,
    colorsDistributor:{
      "П": "yellow darken-3",
      "Ф": "deep-orange lighten-2",
      "Т": "primary lighten-2",
      "": ''
    },
    headers: [],
    headersSortDefault:{
      'default': [{"value":"index","view":true},{"value":"date","view":true},{"value":"date_pay","view":false},{"value":"service","view":true},{"value":"address","view":true},{"value":"operator","view":true},{"value":"amount","view":true},{"value":"type_pay","view":true},{"value":"memo","view":true}],
      'more': [{"value":"index","view":true},{"value":"date","view":true},{"value":"date_pay","view":true},{"value":"service","view":true},{"value":"address","view":true},{"value":"operator","view":true},{"value":"amount","view":true},{"value":"type_pay","view":true},{"value":"memo","view":true}],
    },
    headersDefault: [
      { text: '#', value: 'index', view: true, sortable: false,  width: 50},
      { text: 'Дата', value: 'date', view: true,  },
      { text: 'Проведення', value: 'date_pay', view: false  },
      { text: 'Пос.', value: 'service', view: true, sortable: false,  },
      { text: 'Абонент', value: 'address', view: true, sortable: false,  },
      { text: 'ПС.', value: 'operator', view: true,  sortable: false, },
      { text: "Оплата", value: 'amount', view: true, class: 'text-end', cellClass: 'text-end' },
      { text: 'Тип', value: 'type_pay', view: true, sortable: false, },
      { text: 'Примітки', value: 'memo', view: true, sortable: false, },
    ],
    headers_admin: [
      //
    ],
    footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
    },
      
  }),
  computed: {
      ...mapGetters({
          operators: "filters/operators",
          pays_type: "filters/pays_type"
      }),
      filters_allPays: function () {
        return {
          start_date: new Date().toDateInputValue(),
          end_date: new Date().toDateInputValue()
        }
      },
  },
  watch: {
    tabData: {
      handler () {
        this.pays = []
        this.clearFiltersSettings()
        this.filtersSettings()
        //this.checkGetJob()
      },
      deep: true,
    },
    options: {
      handler (val, oldVal) {
        //console.log(this)
        //console.log("oldVal.page:", oldVal.page, oldVal, "val.page: ", val.page, val )
        oldVal.page && this.getPays();
        
      },
      deep: true,
    },
    updateTable(){
      this.updateTable && this.getPays();
    },
    '$parent.$refs.navbarTable.counterApplyDate'(){
      this.applyNavbarDate(true) 
    }
  },
  mounted (){
    
    //this.applyNavbarDate(true) 
  },
  activated() {
    //this.headers = settingsTableWider && headersMore || defaultHeaders
    
    //this.applyNavbarDate()
  },
  deactivated(){
    //
  },
  methods: {
    onClickRow(){
      //
    },
    formatMemo(memo){
      if(memo.length > 9 ){
        return memo.substr(0, 9) + '...'
      }
      else return memo
    },
    findDistributor (distributor, method) {
      let find_distributor = this.operators.find(x => x.id == distributor)
      if(!find_distributor) return
      if(method == 'short') return find_distributor.short_name
      else if(method == 'title') return find_distributor.name
      else if(method == 'letter') return find_distributor.name[0]
    },
    applyNavbarDate(request=false){
      this.$refs.filtersPays.filters.find(x => x.name == "start_date").value = this.$parent.$refs.navbarTable.filter_date
      this.$refs.filtersPays.filters.find(x => x.name == "end_date").value = this.$parent.$refs.navbarTable.filter_date
      request && this.checkGetPays()
    },
    applyFiltersForTab(){
      for (let filter in this[this.tabData.filters]) {
        this.$parent.$refs.navbarTable.filter_date = new Date().toDateInputValue()
        this.$refs.filtersPays.filters.find(x => x.name == filter).value = this[this.tabData.filters][filter]
        //this.$refs.filtersPays.filters.find(x => x.name == filter).disable = true // вимкнути вибір фільтру
      }
      this.checkGetPays()
      return true
    },
    clearFiltersSettings(){
      this.$refs.filtersPays.filters.forEach(element => { element.value = "", element.disable = false });
    },
    filtersSettings(){
      this.tabData && this.tabData.filters && this.applyFiltersForTab() || this.checkGetPays()
    },
    checkGetPays(){
      this.$parent.$refs.totalPays.getTotalPays(this.$refs.filtersPays.filters, this.inetPays)
      //console.log("checkGetPays")
      if(this.options.page == 1){
        this.getPays();
      }else{
        this.page = 1;
      }
    },
    clearFilter(filter_name){
      this.$refs.filtersPays.filters.find(x => x.name == filter_name).value = ''
      this.$refs.filtersPays.apply()
    },
    getFiltersOfComponents(){
      return this.$refs.filtersPays.filters.filter(x => x.value && x.value != '') 
    },
    filterFormat() {
      this.countFilters = 0
      const temp = {};
      temp.ordering = !this.options.sortDesc[0] && '-' + this.options.sortBy[0] || this.options.sortBy[0];
      temp.itemsPerPage = this.options.itemsPerPage;
      temp.page = this.options.page;
      
      //if(this.search) temp.search = this.search
      
      /* получение значений фильтрации и просчет колличества фильтров */
      this.filtersComponents = this.getFiltersOfComponents()
      this.filtersComponents.forEach(filter => temp[filter.name] = filter.value + '');
      this.countFilters = this.filtersComponents.length
      
      return temp; 
    },
    getPays(){
      //console.log("getPays()")
      this.selectedTableRows = []
      this.loading = true;
      this.$parent.$refs.navbarTable.disabled_date_field = true; // отключаем применение даты до окончания текущего запроса
      if(this.inetPays){
          axios({
              method: "get",
              url: this.$router.app.devUrlBillingAPI,
              params: {req_cmd: "getPayments", ...this.filterFormat() },
              
          })
          .then( response =>{
            //console.log(response.data)
            if(response.data.resp_status == "OK"){
              this.pays = response.data.resp_result.result
              //this.paginations.count = response.data.resp_result.length
              this.paginations = response.data.resp_result.paginations
            }else{
              this.pays = []
              this.paginations = {}
            }
          })
          .catch(() => {})
          .finally(() => (this.loading = false, this.$parent.$refs.navbarTable.disabled_date_field = false)) 
        }else{
          axios.get(`/api/cabletv/pays/`, {params: this.filterFormat()})
          .then( response =>{
            //console.log(response.data)
            this.pays = response.data.results
            this.paginations = response.data.paginations
            //this.$parent.$refs.totalPays.total = response.data.total
            //console.log(this.$parent.$refs.totalPays.total)
          })
          .catch(() => {
            //console.log(error);
            //this.errored = true;
          })
          .finally(() => (this.loading = false, this.$parent.$refs.navbarTable.disabled_date_field = false));
        }
    },
    async openAbonentInfoModal(abonent){
      this.$refs.AbonentInfoModal.paramsUrlId = abonent.user
      this.$refs.AbonentInfoModal.dialog = true
    },
  }
  
}
</script>
<style scoped>
.link_text{
  text-decoration: none;
  cursor: pointer;
  color: #1565C0
}
.handle {
  cursor: grab;
  }
  .handle-click {
    cursor: pointer;
  }
</style>